import React from 'react';

import {
  Footer,
  useTemplate,
  TimeoutModal,
  AccessibilityButton,
  AccessibilityWrapper,
} from '@fingermarkglobal/cringer.components';

import Standing from '@fingermarkglobal/cringer.components/src/assets/images/icons/accessibility/standing.svg';
import Wheelchair from '@fingermarkglobal/cringer.components/src/assets/images/icons/accessibility/wheelchair.svg';

import { Decoration } from '../../components/stateless/decoration';
import { Header } from '../../components/stateless/header';

import CartIconImage from '../../assets/images/cart-icon.svg';

const CustomDefaultTemplate = ({
  children,
  footerEnabled = true,
  headerEnabled = true,
  timeoutEnabled = true,
  templateOverride = false,
  customBackgroundImage = 'bg-default',
  accessibilityActionEnabled = true,
}) => {
  const {
    header,
    footer,
    isOnline,
    isCouponEnabled,
    accessibilityEnabled,
    isTimeoutEnabled,
    timeoutActionOverride,
    modalAccessibilityClass,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
  });

  const showFooterAccessibilityButton = accessibilityActionEnabled && footerEnabled;
  const isAccessibilityButtonEnabled = accessibilityActionEnabled || showFooterAccessibilityButton;

  const showLogo = accessibilityEnabled && isAccessibilityButtonEnabled;
  const isDecorationEnabled = customBackgroundImage === '' || showLogo;

  return (
    <div className="flex flex-col justify-between h-screen">
      {isDecorationEnabled && <Decoration showLogo={showLogo} />}

      <AccessibilityWrapper
        isAccessibilityButtonEnabled={isAccessibilityButtonEnabled}
        className={`bg-default bg-cover flex flex-col md:bg-cover bg-top md:bg-top ${!accessibilityEnabled &&
          'accessibility-static'}`}
      >
        <div className="flex flex-col w-screen h-full">
          {headerEnabled && <Header {...header} isCouponEnabled={isCouponEnabled} />}

          <div className="flex flex-col h-full overflow-auto">{children}</div>

          {footerEnabled && (
            <Footer
              {...footer}
              OverwriteCartIcon={CartIconImage}
              accessibilityEnabled={accessibilityEnabled}
              showAccessibilityButton={showFooterAccessibilityButton}
            />
          )}

          {accessibilityActionEnabled && (
            <AccessibilityButton
              AccessibilityIcon={accessibilityEnabled ? Standing : Wheelchair}
              className="absolute bottom-0 left-0 z-40 block w-24 h-24 p-2 my-8 ml-8 shadow-lg bg-secondary"
            />
          )}

          {isTimeoutEnabled && isOnline && (
            <TimeoutModal
              onTimeout={timeoutActionOverride}
              modalAccessibilityClass={modalAccessibilityClass}
            />
          )}
        </div>
      </AccessibilityWrapper>
    </div>
  );
};

export { CustomDefaultTemplate };
