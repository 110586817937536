import React from 'react';

import {
  BeaconForm,
  BeaconFormHeader,
  useRestaurantBeaconView,
  BeaconFormHeaderIcon,
} from '@fingermarkglobal/cringer.components';
import BeaconImage from '../../assets/images/beacon.png';

const CustomBeaconView = () => {
  const {
    t,
    inputError,
    beaconValue,
    currentSettings,
    allowSubmit,
    secondaryAction,
    primaryAction,
    showPrimaryAction,
    showSecondaryAction,
  } = useRestaurantBeaconView();
  return (
    <div className="flex flex-col items-center w-full p-8 space-y-8 overflow-y-auto mt-32">
      <BeaconFormHeader
        className="w-full text-7xl uppercase mb-8"
        t={t}
        Icon={BeaconFormHeaderIcon({ className: 'w-32', Image: BeaconImage })}
      />
      <BeaconForm
        allowPrimaryAction={allowSubmit}
        beaconValue={beaconValue}
        className="flex flex-col items-center justify-center w-full"
        currentSettings={currentSettings}
        inputError={inputError}
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
        showPrimaryAction={showPrimaryAction}
        showSecondaryAction={showSecondaryAction}
        t={t}
      />
    </div>
  );
};

export { CustomBeaconView };
