import React from 'react';

import {
  BannerWarning,
  PaymentMethods,
  LocaleSelection,
  ServiceCardButton,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import Logo from '../../../assets/images/logo.svg';
import DineInImage from '../../../assets/images/old/dinein.svg';
import TakeawayImage from '../../../assets/images/old/takeaway.svg';
import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultBackgroundImage from '../../../assets/images/background.jpg';

const PrimaryHome = ({
  t = () => {},
  card,
  cash,
  icons,
  languages,
  healthMsg = true,
  className = '',
  primaryAction,
  secondaryAction,
  onChangeLanguage,
  accessibilityEnabled = false,
  setShowTermsAndConditionsModal = () => {},
  showTermsAndConditionsButton = false,
}) => {
  const isBordered = !!(icons?.length || card);

  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackgroundImage,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="primary-home"
        className={`z-10 flex flex-col justify-between items-center h-full w-full overflow-y-hidden  ${className}`}
      >
        <div
          data-test="home-upper"
          className="flex flex-col items-center justify-start w-full h-full mb-32"
        >
          <PaymentMethods
            t={t}
            card={card}
            cash={cash}
            Logo={Logo}
            icons={icons}
            enableLogo={!accessibilityEnabled}
            className={`px-12 py-4 text-7xl rounded text-secondary ${
              isBordered ? 'border-2 border-white' : ''
            } ${isBordered && !accessibilityEnabled ? 'mt-8' : ''}`}
          />
        </div>

        <div
          data-test="home-lower"
          className="flex flex-col-reverse items-center justify-start w-full h-full"
        >
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-terms"
              className={`w-4/5 mb-${healthMsg ? '2' : '48'}`}
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-health"
              image={WashHandsImage}
              className={`w-4/5 mb-${showTermsAndConditionsButton ? '2' : '48'}`}
            />
          )}

          <div
            data-test="home-services"
            className={`flex items-stretch justify-center w-4/5 ${
              healthMsg ? 'mb-32' : 'mb-96'
            } space-x-16`}
          >
            {!!secondaryAction ? (
              <ServiceCardButton
                data-test="button-secondary"
                className="w-1/2 shadow-lg bg-secondary"
                onClick={secondaryAction}
              >
                <div className="mt-4">{t('homeSecondaryAction')}</div>
                <DineInImage className="w-64 " />
              </ServiceCardButton>
            ) : null}

            {!!primaryAction ? (
              <ServiceCardButton
                data-test="button-primary"
                className="w-1/2 shadow-lg bg-secondary"
                onClick={primaryAction}
              >
                <div className="mt-4">{t('homePrimaryAction')}</div>
                <TakeawayImage className="w-32" />
              </ServiceCardButton>
            ) : null}
          </div>
        </div>

        <div className="flex justify-center w-full mt-8" data-test="home-footer">
          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { PrimaryHome };
